import { initializeApp } from "firebase/app";
import {  getAuth, signInAnonymously, onAuthStateChanged, connectAuthEmulator, signInWithCustomToken } from 'firebase/auth';
import { getFirestore, doc, getDoc, getDocs, collection, query, orderBy, limit, startAfter, writeBatch, connectFirestoreEmulator } from 'firebase/firestore';
import { getFunctions, httpsCallable, connectFunctionsEmulator } from 'firebase/functions';
import { genUUID } from "./utils/Misc.mjs";
import { getStorage, ref, uploadBytesResumable, connectStorageEmulator } from "firebase/storage";

const pathname = window.location.pathname;
const campaignId = pathname.split('/')[1];	

const firebaseConfig = {
	apiKey: "AIzaSyBD_NtznWHLPV4JiSVbPLz8k6LBfiCNG90",
	authDomain: "morepolls-1cc1e.firebaseapp.com",
	projectId: "morepolls-1cc1e",
	storageBucket: "morepolls-1cc1e.appspot.com",
	messagingSenderId: "817940652447",
	appId: "1:817940652447:web:bd58c497105e805fa2ddaa"
};
const firebaseApp = initializeApp(firebaseConfig);
const auth = getAuth(firebaseApp);
if(process.env.NODE_ENV === 'development') connectAuthEmulator(auth, "http://localhost:28754");

const db = getFirestore(firebaseApp);
if(process.env.NODE_ENV === 'development') connectFirestoreEmulator(db, "localhost", 29479);

const functions = getFunctions(firebaseApp, "europe-west1");
if(process.env.NODE_ENV === 'development') connectFunctionsEmulator(functions, 'localhost', 28764);

const storage = getStorage();
if(process.env.NODE_ENV === 'development') connectStorageEmulator(storage, 'localhost', 9199);

const extractUserId = function(search){
	if(typeof search === 'string' && search.trim()){
		return new URLSearchParams(search).get('user_id');
	}
	return null;
}
const url_user_id = extractUserId(window.location.search);
/* Login manager */
const loggedInListeners = [];
function loggedIn() {
	if(loginStatus === 'LOGGED_IN') return;
	loginStatus = 'LOGGED_IN';
	let listener = null;
	while((listener = loggedInListeners.pop())){
		listener();
	}
}
let loginStatus = null;
async function logInAnonymously() {
	if(loginStatus===null || loginStatus==='ERROR' ){
		loginStatus = 'LOGGING_IN';

		(url_user_id?getAppLoginToken(url_user_id).then((data)=>signInWithCustomToken(auth, data.token)):signInAnonymously(auth))
		.then(() => {
			console.log('Signed in', auth.currentUser.uid);
		})
		.catch((error) => {

			let deviceId = localStorage.getItem('deviceId') || 'undefined';
			deviceId = deviceId.replaceAll('"', '');
			const userId = auth?.currentUser?.uid || 'undefined';
			const actionId = genUUID();
			const time = new Date().getTime();
			const logPayload = {
				"campaignId": campaignId || 'undefined',
				"time": time,
				"userId": userId,
				"deviceId": deviceId,
				"uuid": actionId,
				"type": 'error',
				"pageId": 'undefined',
				"message": `Failed to sign in ${JSON.stringify(error)}, Details: ${JSON.stringify(error.details)}`,

			}
			localStorage.setItem(`unsent_${campaignId}_${deviceId}`, JSON.stringify(logPayload))
			console.error('Failed to signin', error, error.details);
			loginStatus = 'ERROR';
		});
	}
}
async function onceLogIn(cb) {
	if(loginStatus === 'LOGGED_IN') return cb();
	else{
		loggedInListeners.push(cb);
	}
}
onAuthStateChanged(auth, (user) => {
	if (user) {
		const uid = user?.uid||'';
		if(url_user_id && ('::'+url_user_id+'::')!==uid) return;
		if(!url_user_id && uid.startsWith('::') && uid.endsWith('::')) return;
		// User is signed in, see docs for a list of available properties
		// https://firebase.google.com/docs/reference/js/firebase.User
		// console.log('user', user)
		loggedIn();
	} else {
		// User is signed out
	}
});
logInAnonymously();

function logout() {
	auth.signOut();
	window.location.reload();
}

async function getCampaign(campaignId){
	if(!campaignId) return Promise.resolve(undefined);
	return new Promise(function (resolve, reject) {
		onceLogIn(async function () {
			const campaignRef = doc(db, "campaigns", campaignId);
			const responseRef = doc(db, "campaigns", campaignId, "responses", auth.currentUser.uid);

			const [campaignDoc, responseDoc] = await Promise.all([getDoc(campaignRef), getDoc(responseRef)])

			if (campaignDoc.exists()) {
				const campaign = campaignDoc.data();
				const responses = responseDoc.data();
				resolve({campaign, responses, "user_id": auth.currentUser.uid});
			}
			else {
				resolve(undefined);
			}
		})
	})
}
async function submitPage(campaignId, pageId, data) {
	const dt = await httpsCallable(functions, 'app/vote/'+campaignId+'/'+pageId)(data);
	return dt.data;
}
async function getAppLoginToken(customUserToken) {
	const dt = await httpsCallable(functions, 'app/getAppLoginToken/')({customUserToken});
	return dt.data;
}
async function fetchScratchTicket(campaignId, elementId) {
	const dt = await httpsCallable(functions, `app/get/campaignsData/${campaignId}/scratchTickets/${elementId}`)();
	return dt.data;
}

async function uploadTemporaryFile(imageName, file, onStateChange, onSuccess, onFail){
	const storageRef = ref(storage, `tmp/${auth.currentUser.uid}/${imageName}`);

	const uploadTask = uploadBytesResumable(storageRef, file);
	uploadTask.on('state_changed', onStateChange, onFail, onSuccess);
}

async function getPosts(campaignId, elementId, getAfter=null, count=50){
	if(!campaignId) return undefined;
	const campaignsRef = collection(db, "campaigns", campaignId, "mediaWalls", elementId, "posts");
	const batch = getAfter?await query(campaignsRef, orderBy("timestamp", "desc"), limit(count), startAfter(getAfter)):await query(campaignsRef, orderBy("timestamp", "desc"), limit(count));

	const out = [];
	const docs = await getDocs(batch);
	docs.forEach((doc)=>{
		out.push({"id": doc.id, "data": doc.data(), "doc": doc});
	});
	return out;
}

async function sendLogs(logs){
	let segment;
	while( (segment = logs.splice(0,200)).length ) {
		const batch = writeBatch(db);
		segment.forEach(({campaignId, time, userId, deviceId, uuid, type, ...other})=>{
			const dt = {campaignId, time, userId, deviceId, uuid, type, ...other};
			const path = ["campaignLogs", campaignId, 'logs', uuid];
			console.log('Adding:', dt, 'to', path.join('/'))
			const logRef = doc(db, ...path);
			batch.set(logRef, dt);
		});
		await batch.commit();
	}
}

export {
	logout,
	getCampaign,
	submitPage,
	fetchScratchTicket,
	uploadTemporaryFile,
	getPosts,
	sendLogs,
};
