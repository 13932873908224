import { useState, useEffect } from "react";


function UGCFieldInput({ name, value, label, placeholder, isRequired, onChange, inputType, elementErrors, charLimit }) {
	const [err, setErr] = useState(false);

	const getInputStyling = () => {
		let className = "FormFieldInput mtop--15";
		if (err) className += ' input--error';
		return className;
	}	
	
	useEffect(() => {
		elementErrors?.errors?.forEach(function (e) {
			setErr(true);
		});
	}, [elementErrors])

	const handleInputChange = (e) => {
		let updatedValue = e.target.value
		
		if(value.length >= charLimit && updatedValue.length > value.length) return
		
		if(updatedValue.length > charLimit){
			updatedValue = updatedValue.substr(0, charLimit)
		}
		onChange(name, updatedValue)
	}

	return (
		<div className={getInputStyling()}>
			<label>{label && label}</label>
			{isRequired ? <b className="text--danger">*</b> : ''}
			<input onChange={handleInputChange} value={value} placeholder={placeholder} type={inputType} />
			{elementErrors?.errors.map((error) => <div className="text--danger">{error.message}</div>)}
			{charLimit && value.length >= charLimit && <div className="font--14 text--muted">&nbsp;Max character limit reached.</div>}
		</div>
	);
};
export default UGCFieldInput;
